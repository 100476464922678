import i18n from '@/libs/i18n'

export default {
    A_0: i18n.t('orders.A_0'),
    A_1: i18n.t('orders.A_1'),
    A_2: i18n.t('orders.A_2'),
    A_3: i18n.t('orders.A_3'),
    A_4: i18n.t('orders.A_4'),
    A_5: i18n.t('orders.A_5'),
    A_6: i18n.t('orders.A_6'),
    A_7: i18n.t('orders.A_7'),
    A_8: i18n.t('orders.A_8'),
    A_9: i18n.t('orders.A_9'),
    A_10: i18n.t('orders.A_10'),
    A_11: i18n.t('orders.A_11'),
    A_12: i18n.t('orders.A_12'),
    A_13: i18n.t('orders.A_13'),
    A_14: i18n.t('orders.A_14')
}