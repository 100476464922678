<template>
  <div id="orders">
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Order') }}</h1>
        <h5 class="text-primary">
          {{ $t('shopOrder') }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-end">
        <div class="flatpickr d-flex align-items-center">
          <b-form-select
            v-if="!userVendor"
            v-model="vendor"
            class="mr-2"
          >
            <b-form-select-option :value="null">
              {{ $t('filterVend') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="coun in allVendors"
              :key="coun.node.id"
              :value="coun.node.id"
            >
              {{ coun.node.vendorName }}
            </b-form-select-option>
          </b-form-select>
          <flat-pickr
            id="pickr"
            v-model="filterDate"
            class="form-control mr-2"
            :placeholder="$t('filtDate')"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d'
            }"
          />

          <a
            class="input-button"
            title="toggle"
            @click="clickPickr()"
          >
            <feather-icon
              icon="CalendarIcon"
              size="22"
              class="mr-1"
            />

          </a>

          <a
            v-if="filterDate.length != 0"
            class="input-button"
            title="clear"
            @click="filterDate = ''"
          >
            <feather-icon
              icon="XIcon"
              size="22"
              class="mr-1"
            />

          </a>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="refInvoiceListTable"
          :items="allOrder"
          :busy="isBusy"
          responsive
          :fields="tableColumns"
          primary-key="id"
          select-mode="single"
          show-empty
          :empty-text="$t('dataNotFound')"
          class="cursor-pointer text-justify"
          selectable
          @row-selected="rowSelected"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}...</strong>
            </div>
          </template>
          <template #cell(order_id)="data">
            <div>
              {{ data.item.node.orderId }}
            </div>
          </template>
          <template #cell(customer)="data">
            <div>
              {{ cleanUsername(data.item.node.customer.username) }}
            </div>
          </template>
          <template #cell(chargeToken)="data">
            <div>
              {{ data.item.node.chargeToken }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              {{ status[data.item.node.status] }}
            </div>
          </template>
          <template #cell(vendor)="data">
            <div>
              {{ data.item.node.vendor != null ? data.item.node.vendor.vendorName : '' }}
            </div>
          </template>
          <template #cell(shippingCost)="data">
            <div>
              {{ data.item.node.shippingCost }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(totals)="data">
            <div>
              {{ data.item.node.totalAmount == null ? 0 : format(data.item.node.totalAmount) }}
              {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(refunded)="data">
            <div>
              {{ data.item.node.totalRefunded == null ? 0 : format(data.item.node.totalRefunded) }}
              {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(create_at)="data">
            <div>
              {{ fechaFormato(data.item.node.created) }}
            </div>
          </template>
          <template #cell(show_details)="data">
            <b-button
              size="sm"
              :variant="data.detailsShowing ? 'danger' : 'success'"
              class="cursor-pointer"
              :disabled="data.item.node.products.edges.length == 0"
              @click="data.toggleDetails"
            >
              {{ data.detailsShowing ? $t('table.hidden') : $t('table.Show') }} productos
            </b-button>
          </template>
          <template #row-details="row">
            <b-card :title="$t('Productos')">
              <b-row class="mb-1">
                <b-col>
                  <h6>
                    {{ $t('firstName') }}
                  </h6>
                </b-col>
                <b-col>
                  <h6>{{ $t('quantity') }}</h6>
                </b-col>
                <b-col>
                  <h6>{{ $t('purchases.price') }}</h6>
                </b-col>
              </b-row>
              <b-row
                v-for="prod in row.item.node.products.edges"
                :key="prod.node.id"
                class="mb-1"
              >
                <b-col>{{ prod.node.itemName }}</b-col>
                <b-col>{{ prod.node.itemQuantity }} </b-col>
                <b-col>{{ prod.node.itemPrice }} {{ currencyType[currency] }}</b-col>
              </b-row>
              <b-row>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="row.toggleDetails"
                >
                  {{ $t('dataGeneric.close') }}
                </b-button>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex ">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="fetchData"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BSpinner,
  BButton,
  BTable,
  BFormSelect,
  BFormSelectOption,
  BPagination,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import {
  messageError, cleanUsername, utf8ToB64, getVendorProfileData,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import statusOrder from '@core/utils/statusOrder'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    BButton,
    BTable,
    BFormSelectOption,
    BFormSelect,
    flatPickr,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: getUserData(),
      selectOrder: null,
      orders: null,
      isBusy: false,
      allOrder: [],
      userVendor: false,
      vendor: null,
      allVendors: [],
      filterDate: '',
      currency: null,
      tableColumns: [
        { key: 'order_id', label: this.$t('orderId') },
        { key: 'customer', label: this.$t('Cliente') },
        { key: 'chargeToken', label: this.$t('idPaid') },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'vendor', label: this.$t('Vendedor') },
        { key: 'shippingCost', label: this.$t('sendCost') },
        { key: 'totals', label: this.$t('Totales') },
        { key: 'refunded', label: this.$t('Devuelto') },
        { key: 'create_at', label: this.$t('subscriptions.createdAt') },
        { key: 'show_details', label: this.$t('Productos') },
      ],
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
        isInternal: false,
      },
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      status: statusOrder,

      rows: 0,
      perPage: 20,
      currentPage: 1,
    }
  },
  watch: {
    filterDate() {
      this.fetchData()
    },
    vendor() {
      this.fetchData()
    },
  },
  async mounted() {
    if (this.userData.profile.isVendor) {
      await getVendorProfileData(this.userData.profile.id).then(result => {
        if (result) {
          this.vendor = result.id
        }
      })
      this.userVendor = true
    } else {
      this.userVendor = true
      this.vendor = null
    }

    this.getVendor()
    this.getPayMethod()
    this.fetchData()
  },
  methods: {
    format(num) {
      const reg = /\d{1,3}(?=(\d{3})+$)/g
      return (`${num}`).replace(reg, '$&.')
    },
    cleanUsername,
    seleccionar() {
      this.$emit('order', this.selectOrder)
    },

    rowSelected(data) {
      this.selectOrder = data[0].node
      this.seleccionar()
    },

    fetchData() {
      this.isBusy = !this.isBusy

      const query = `
                {
                    allOrder(
                            orderBy:"order_id",
                            first:${this.perPage},
                            ${this.vendor != null ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}"` : ''}
                            offset:${this.perPage * (this.currentPage - 1)},
                            prod:true,
                            ${this.filterDate !== '' ? `created:"${this.filterDate}",` : ''}
                            client: "${this.userData.profile.client.id}") {
                            totalCount
                            edges {
                            node {
                                id
                                totalAmount
                                totalRefunded
                                shippingCost
                                chargeToken
                                status
                                orderId
                                updated
                                products {
                                    edges {
                                    node {
                                        id
                                        itemId
                                        itemName
                                        itemModel
                                        itemPrice
                                        itemBasePrice
                                        itemQuantity
                                        itemTaxId
                                        itemImage
                                        itemVariantId
                                        
                                    }
                                }
                            }
                            client {
                            id
                            name
                            }
                            vendor {
                                id
                                vendorName
                                name
                                lastName
                                vendorImage
                                imageVendorUrl
                                
                            }
                            customer {
                            id
                            username
                            }
                            created
                            }
                        }
                        }
                    }
                `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.allOrder = result.data.data.allOrder.edges
          this.rows = result.data.data.allOrder.totalCount
          this.isBusy = !this.isBusy
        })
        .catch(err => { console.log(err) })
    },
    getVendor() {
      const query = `{
                allVendors(client:"${this.userData.profile.client.id}",order:true) {
                    totalCount
                    edges {
                        node {
                            id
                            name
                            vendorName
                            
                            cartVendor {
                            edges {
                            node {
                                id
                                orderId
                                
                            }
                            }
                        }                    
                        client {
                            id
                            name
                        }
                        }
                    }
                    }
                }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)
          this.allVendors = result.data.data.allVendors.edges
        }).catch(err => {
          console.log(err)
        })
    },
    clickPickr() {
      document.getElementById('pickr').click()
    },
    getPayMethod() {
      axios
        .post('', {
          query: `
                  query{
                    client(id:"${this.userData.profile.client.id}"){
                      externalPayMethod
                      currency
                    }
                }
            `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(res => {
          console.log(res)
        })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder.imageVendorUrl == null || folder.imageVendorUrl.length === 0
        ? noCover
        : folder.imageVendorUrl
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#orders .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#orders .cardContent img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
            height:  100%;
            object-fit: cover;*/
}

#orders .card-body {
  padding: 1rem;
}

#orders .card-body h4 {
  font-size: 1rem !important;
}

#orders .card-header {
  padding: 1rem;
}

#orders .card-header h4 {
  font-size: 1rem !important;
}

#orders .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

#orders #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#orders #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
